/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/style-prop-object */
import React from 'react';

const PatternIcon = () => (
  <svg width="125" height="158" viewBox="0 0 125 158" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_56_6211" maskUnits="userSpaceOnUse" x="0" y="0" width="125" height="158">
      <rect y="0.937256" width="125" height="156.25" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_56_6211)">
      <g opacity="0.3">
        <mask id="mask1_56_6211" maskUnits="userSpaceOnUse" x="17" y="22" width="12" height="11">
          <path d="M22.8712 32.8008C25.7604 32.8008 28.1027 30.5192 28.1027 27.7048C28.1027 24.8904 25.7604 22.6089 22.8712 22.6089C19.9819 22.6089 17.6396 24.8904 17.6396 27.7048C17.6396 30.5192 19.9819 32.8008 22.8712 32.8008Z" fill="url(#paint0_linear_56_6211)" />
        </mask>
        <g mask="url(#mask1_56_6211)">
          <path d="M22.8712 32.7989C25.7604 32.7989 28.1027 30.5173 28.1027 27.7029C28.1027 24.8885 25.7604 22.6069 22.8712 22.6069C19.9819 22.6069 17.6396 24.8885 17.6396 27.7029C17.6396 30.5173 19.9819 32.7989 22.8712 32.7989Z" fill="#AD7F27" />
        </g>
        <mask id="mask2_56_6211" maskUnits="userSpaceOnUse" x="47" y="22" width="12" height="11">
          <path d="M53.171 32.8008C56.0602 32.8008 58.4025 30.5192 58.4025 27.7048C58.4025 24.8904 56.0602 22.6089 53.171 22.6089C50.2817 22.6089 47.9395 24.8904 47.9395 27.7048C47.9395 30.5192 50.2817 32.8008 53.171 32.8008Z" fill="url(#paint1_linear_56_6211)" />
        </mask>
        <g mask="url(#mask2_56_6211)">
          <path d="M53.171 32.799C56.0602 32.799 58.4025 30.5174 58.4025 27.7029C58.4025 24.8885 56.0602 22.6069 53.171 22.6069C50.2817 22.6069 47.9395 24.8885 47.9395 27.7029C47.9395 30.5174 50.2817 32.799 53.171 32.799Z" fill="url(#paint2_linear_56_6211)" />
        </g>
        <mask id="mask3_56_6211" maskUnits="userSpaceOnUse" x="78" y="22" width="11" height="11">
          <path d="M83.4698 32.8008C86.3591 32.8008 88.7013 30.5192 88.7013 27.7048C88.7013 24.8904 86.3591 22.6089 83.4698 22.6089C80.5805 22.6089 78.2383 24.8904 78.2383 27.7048C78.2383 30.5192 80.5805 32.8008 83.4698 32.8008Z" fill="url(#paint3_linear_56_6211)" />
        </mask>
        <g mask="url(#mask3_56_6211)">
          <path d="M83.4698 32.7989C86.3591 32.7989 88.7013 30.5173 88.7013 27.7029C88.7013 24.8885 86.3591 22.6069 83.4698 22.6069C80.5805 22.6069 78.2383 24.8885 78.2383 27.7029C78.2383 30.5173 80.5805 32.7989 83.4698 32.7989Z" fill="url(#paint4_linear_56_6211)" />
        </g>
        <mask id="mask4_56_6211" maskUnits="userSpaceOnUse" x="108" y="22" width="11" height="11">
          <path d="M113.769 32.8002C116.658 32.8002 119 30.5187 119 27.7044C119 24.8901 116.658 22.6086 113.769 22.6086C110.879 22.6086 108.537 24.8901 108.537 27.7044C108.537 30.5187 110.879 32.8002 113.769 32.8002Z" fill="url(#paint5_linear_56_6211)" />
        </mask>
        <g mask="url(#mask4_56_6211)">
          <path d="M113.769 32.799C116.658 32.799 119 30.5174 119 27.7029C119 24.8885 116.658 22.6069 113.769 22.6069C110.879 22.6069 108.537 24.8885 108.537 27.7029C108.537 30.5174 110.879 32.799 113.769 32.799Z" fill="#AD7F27" />
        </g>
        <mask id="mask5_56_6211" maskUnits="userSpaceOnUse" x="17" y="48" width="12" height="11">
          <path d="M22.8712 58.3878C25.7604 58.3878 28.1027 56.1063 28.1027 53.2919C28.1027 50.4775 25.7604 48.196 22.8712 48.196C19.9819 48.196 17.6396 50.4775 17.6396 53.2919C17.6396 56.1063 19.9819 58.3878 22.8712 58.3878Z" fill="url(#paint6_linear_56_6211)" />
        </mask>
        <g mask="url(#mask5_56_6211)">
          <path d="M22.8712 58.3878C25.7604 58.3878 28.1027 56.1063 28.1027 53.2919C28.1027 50.4775 25.7604 48.196 22.8712 48.196C19.9819 48.196 17.6396 50.4775 17.6396 53.2919C17.6396 56.1063 19.9819 58.3878 22.8712 58.3878Z" fill="url(#paint7_linear_56_6211)" />
        </g>
        <mask id="mask6_56_6211" maskUnits="userSpaceOnUse" x="47" y="48" width="12" height="11">
          <path d="M53.171 58.3878C56.0602 58.3878 58.4025 56.1063 58.4025 53.2919C58.4025 50.4775 56.0602 48.196 53.171 48.196C50.2817 48.196 47.9395 50.4775 47.9395 53.2919C47.9395 56.1063 50.2817 58.3878 53.171 58.3878Z" fill="url(#paint8_linear_56_6211)" />
        </mask>
        <g mask="url(#mask6_56_6211)">
          <path d="M53.171 58.3878C56.0602 58.3878 58.4025 56.1063 58.4025 53.2919C58.4025 50.4775 56.0602 48.196 53.171 48.196C50.2817 48.196 47.9395 50.4775 47.9395 53.2919C47.9395 56.1063 50.2817 58.3878 53.171 58.3878Z" fill="#D2BF98" />
        </g>
        <mask id="mask7_56_6211" maskUnits="userSpaceOnUse" x="78" y="48" width="11" height="11">
          <path d="M83.4698 58.3878C86.3591 58.3878 88.7013 56.1063 88.7013 53.2919C88.7013 50.4775 86.3591 48.196 83.4698 48.196C80.5805 48.196 78.2383 50.4775 78.2383 53.2919C78.2383 56.1063 80.5805 58.3878 83.4698 58.3878Z" fill="url(#paint9_linear_56_6211)" />
        </mask>
        <g mask="url(#mask7_56_6211)">
          <path d="M83.4698 58.3878C86.3591 58.3878 88.7013 56.1063 88.7013 53.2919C88.7013 50.4775 86.3591 48.196 83.4698 48.196C80.5805 48.196 78.2383 50.4775 78.2383 53.2919C78.2383 56.1063 80.5805 58.3878 83.4698 58.3878Z" fill="#AD7F27" />
        </g>
        <mask id="mask8_56_6211" maskUnits="userSpaceOnUse" x="108" y="48" width="11" height="11">
          <path d="M113.769 58.3876C116.658 58.3876 119 56.1061 119 53.2917C119 50.4773 116.658 48.1958 113.769 48.1958C110.879 48.1958 108.537 50.4773 108.537 53.2917C108.537 56.1061 110.879 58.3876 113.769 58.3876Z" fill="url(#paint10_linear_56_6211)" />
        </mask>
        <g mask="url(#mask8_56_6211)">
          <path d="M113.769 58.3873C116.658 58.3873 119 56.1059 119 53.2916C119 50.4772 116.658 48.1958 113.769 48.1958C110.879 48.1958 108.537 50.4772 108.537 53.2916C108.537 56.1059 110.879 58.3873 113.769 58.3873Z" fill="#AD7F27" />
        </g>
        <mask id="mask9_56_6211" maskUnits="userSpaceOnUse" x="17" y="73" width="12" height="11">
          <path d="M22.8712 83.9183C25.7604 83.9183 28.1027 81.6367 28.1027 78.8223C28.1027 76.0079 25.7604 73.7263 22.8712 73.7263C19.9819 73.7263 17.6396 76.0079 17.6396 78.8223C17.6396 81.6367 19.9819 83.9183 22.8712 83.9183Z" fill="url(#paint11_linear_56_6211)" />
        </mask>
        <g mask="url(#mask9_56_6211)">
          <path d="M22.8712 83.9189C25.7604 83.9189 28.1027 81.6373 28.1027 78.8227C28.1027 76.0082 25.7604 73.7266 22.8712 73.7266C19.9819 73.7266 17.6396 76.0082 17.6396 78.8227C17.6396 81.6373 19.9819 83.9189 22.8712 83.9189Z" fill="url(#paint12_linear_56_6211)" />
        </g>
        <mask id="mask10_56_6211" maskUnits="userSpaceOnUse" x="47" y="73" width="12" height="11">
          <path d="M53.171 83.9183C56.0602 83.9183 58.4025 81.6367 58.4025 78.8223C58.4025 76.0079 56.0602 73.7263 53.171 73.7263C50.2817 73.7263 47.9395 76.0079 47.9395 78.8223C47.9395 81.6367 50.2817 83.9183 53.171 83.9183Z" fill="url(#paint13_linear_56_6211)" />
        </mask>
        <g mask="url(#mask10_56_6211)">
          <path d="M53.171 83.9185C56.0603 83.9185 58.4025 81.637 58.4025 78.8225C58.4025 76.0081 56.0603 73.7266 53.171 73.7266C50.2817 73.7266 47.9395 76.0081 47.9395 78.8225C47.9395 81.637 50.2817 83.9185 53.171 83.9185Z" fill="#AD7F27" />
        </g>
        <mask id="mask11_56_6211" maskUnits="userSpaceOnUse" x="78" y="73" width="11" height="11">
          <path d="M83.4698 83.9183C86.3591 83.9183 88.7013 81.6367 88.7013 78.8223C88.7013 76.0079 86.3591 73.7263 83.4698 73.7263C80.5805 73.7263 78.2383 76.0079 78.2383 78.8223C78.2383 81.6367 80.5805 83.9183 83.4698 83.9183Z" fill="url(#paint14_linear_56_6211)" />
        </mask>
        <g mask="url(#mask11_56_6211)">
          <path d="M83.4698 83.9186C86.3591 83.9186 88.7013 81.637 88.7013 78.8226C88.7013 76.0081 86.3591 73.7266 83.4698 73.7266C80.5805 73.7266 78.2383 76.0081 78.2383 78.8226C78.2383 81.637 80.5805 83.9186 83.4698 83.9186Z" fill="url(#paint15_linear_56_6211)" />
        </g>
        <mask id="mask12_56_6211" maskUnits="userSpaceOnUse" x="108" y="73" width="11" height="11">
          <path d="M113.769 83.9185C116.658 83.9185 119 81.6369 119 78.8224C119 76.0079 116.658 73.7263 113.769 73.7263C110.879 73.7263 108.537 76.0079 108.537 78.8224C108.537 81.6369 110.879 83.9185 113.769 83.9185Z" fill="url(#paint16_linear_56_6211)" />
        </mask>
        <g mask="url(#mask12_56_6211)">
          <path d="M113.769 83.9187C116.658 83.9187 119 81.6371 119 78.8226C119 76.0081 116.658 73.7266 113.769 73.7266C110.879 73.7266 108.537 76.0081 108.537 78.8226C108.537 81.6371 110.879 83.9187 113.769 83.9187Z" fill="url(#paint17_linear_56_6211)" />
        </g>
        <mask id="mask13_56_6211" maskUnits="userSpaceOnUse" x="17" y="99" width="12" height="11">
          <path d="M22.8712 109.507C25.7604 109.507 28.1027 107.225 28.1027 104.411C28.1027 101.597 25.7604 99.3152 22.8712 99.3152C19.9819 99.3152 17.6396 101.597 17.6396 104.411C17.6396 107.225 19.9819 109.507 22.8712 109.507Z" fill="url(#paint18_linear_56_6211)" />
        </mask>
        <g mask="url(#mask13_56_6211)">
          <path d="M22.8712 109.507C25.7604 109.507 28.1027 107.226 28.1027 104.411C28.1027 101.597 25.7604 99.3152 22.8712 99.3152C19.9819 99.3152 17.6396 101.597 17.6396 104.411C17.6396 107.226 19.9819 109.507 22.8712 109.507Z" fill="url(#paint19_linear_56_6211)" />
        </g>
        <mask id="mask14_56_6211" maskUnits="userSpaceOnUse" x="47" y="99" width="12" height="11">
          <path d="M53.171 109.507C56.0602 109.507 58.4025 107.225 58.4025 104.411C58.4025 101.597 56.0602 99.3152 53.171 99.3152C50.2817 99.3152 47.9395 101.597 47.9395 104.411C47.9395 107.225 50.2817 109.507 53.171 109.507Z" fill="url(#paint20_linear_56_6211)" />
        </mask>
        <g mask="url(#mask14_56_6211)">
          <path d="M53.1709 109.507C56.0601 109.507 58.4023 107.226 58.4023 104.411C58.4023 101.597 56.0601 99.3152 53.1709 99.3152C50.2816 99.3152 47.9395 101.597 47.9395 104.411C47.9395 107.226 50.2816 109.507 53.1709 109.507Z" fill="url(#paint21_linear_56_6211)" />
        </g>
        <mask id="mask15_56_6211" maskUnits="userSpaceOnUse" x="78" y="99" width="11" height="11">
          <path d="M83.4698 109.507C86.3591 109.507 88.7013 107.225 88.7013 104.411C88.7013 101.597 86.3591 99.3152 83.4698 99.3152C80.5805 99.3152 78.2383 101.597 78.2383 104.411C78.2383 107.225 80.5805 109.507 83.4698 109.507Z" fill="url(#paint22_linear_56_6211)" />
        </mask>
        <g mask="url(#mask15_56_6211)">
          <path d="M83.4698 109.507C86.3591 109.507 88.7013 107.226 88.7013 104.411C88.7013 101.597 86.3591 99.3152 83.4698 99.3152C80.5805 99.3152 78.2383 101.597 78.2383 104.411C78.2383 107.226 80.5805 109.507 83.4698 109.507Z" fill="url(#paint23_linear_56_6211)" />
        </g>
        <mask id="mask16_56_6211" maskUnits="userSpaceOnUse" x="108" y="99" width="11" height="11">
          <path d="M113.769 109.507C116.658 109.507 119 107.225 119 104.411C119 101.596 116.658 99.3149 113.769 99.3149C110.879 99.3149 108.537 101.596 108.537 104.411C108.537 107.225 110.879 109.507 113.769 109.507Z" fill="url(#paint24_linear_56_6211)" />
        </mask>
        <g mask="url(#mask16_56_6211)">
          <path d="M113.769 109.508C116.658 109.508 119 107.227 119 104.412C119 101.598 116.658 99.3162 113.769 99.3162C110.879 99.3162 108.537 101.598 108.537 104.412C108.537 107.227 110.879 109.508 113.769 109.508Z" fill="url(#paint25_linear_56_6211)" />
        </g>
        <mask id="mask17_56_6211" maskUnits="userSpaceOnUse" x="17" y="124" width="12" height="12">
          <path d="M22.8712 135.038C25.7604 135.038 28.1027 132.756 28.1027 129.942C28.1027 127.127 25.7604 124.846 22.8712 124.846C19.9819 124.846 17.6396 127.127 17.6396 129.942C17.6396 132.756 19.9819 135.038 22.8712 135.038Z" fill="url(#paint26_linear_56_6211)" />
        </mask>
        <g mask="url(#mask17_56_6211)">
          <path d="M22.8712 135.038C25.7604 135.038 28.1027 132.757 28.1027 129.942C28.1027 127.128 25.7604 124.846 22.8712 124.846C19.9819 124.846 17.6396 127.128 17.6396 129.942C17.6396 132.757 19.9819 135.038 22.8712 135.038Z" fill="#D2BF98" />
        </g>
        <mask id="mask18_56_6211" maskUnits="userSpaceOnUse" x="47" y="124" width="12" height="12">
          <path d="M53.171 135.038C56.0602 135.038 58.4025 132.756 58.4025 129.942C58.4025 127.127 56.0602 124.846 53.171 124.846C50.2817 124.846 47.9395 127.127 47.9395 129.942C47.9395 132.756 50.2817 135.038 53.171 135.038Z" fill="url(#paint27_linear_56_6211)" />
        </mask>
        <g mask="url(#mask18_56_6211)">
          <path d="M53.171 135.038C56.0602 135.038 58.4025 132.756 58.4025 129.942C58.4025 127.128 56.0602 124.846 53.171 124.846C50.2817 124.846 47.9395 127.128 47.9395 129.942C47.9395 132.756 50.2817 135.038 53.171 135.038Z" fill="#D2BF98" />
        </g>
        <mask id="mask19_56_6211" maskUnits="userSpaceOnUse" x="78" y="124" width="11" height="12">
          <path d="M83.4698 135.038C86.3591 135.038 88.7013 132.756 88.7013 129.942C88.7013 127.127 86.3591 124.846 83.4698 124.846C80.5805 124.846 78.2383 127.127 78.2383 129.942C78.2383 132.756 80.5805 135.038 83.4698 135.038Z" fill="url(#paint28_linear_56_6211)" />
        </mask>
        <g mask="url(#mask19_56_6211)">
          <path d="M83.4698 135.038C86.3591 135.038 88.7013 132.757 88.7013 129.942C88.7013 127.128 86.3591 124.846 83.4698 124.846C80.5805 124.846 78.2383 127.128 78.2383 129.942C78.2383 132.757 80.5805 135.038 83.4698 135.038Z" fill="url(#paint29_linear_56_6211)" />
        </g>
        <mask id="mask20_56_6211" maskUnits="userSpaceOnUse" x="108" y="124" width="11" height="12">
          <path d="M113.769 135.037C116.658 135.037 119 132.756 119 129.941C119 127.127 116.658 124.845 113.769 124.845C110.879 124.845 108.537 127.127 108.537 129.941C108.537 132.756 110.879 135.037 113.769 135.037Z" fill="url(#paint30_linear_56_6211)" />
        </mask>
        <g mask="url(#mask20_56_6211)">
          <path d="M113.769 135.038C116.658 135.038 119 132.756 119 129.942C119 127.128 116.658 124.846 113.769 124.846C110.879 124.846 108.537 127.128 108.537 129.942C108.537 132.756 110.879 135.038 113.769 135.038Z" fill="#AD7F27" />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear_56_6211" x1="68.5895" y1="140.75" x2="-0.031045" y2="-38.0733" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint1_linear_56_6211" x1="94.8102" y1="130.689" x2="26.1898" y2="-48.1346" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint2_linear_56_6211" x1="94.8102" y1="130.689" x2="26.1882" y2="-48.1369" gradientUnits="userSpaceOnUse">
        <stop stopColor="#AD7F27" />
        <stop offset="1" stopColor="#D2BF98" />
      </linearGradient>
      <linearGradient id="paint3_linear_56_6211" x1="121.03" y1="120.627" x2="52.4093" y2="-58.1964" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint4_linear_56_6211" x1="121.03" y1="120.626" x2="52.4086" y2="-58.1986" gradientUnits="userSpaceOnUse">
        <stop stopColor="#AD7F27" />
        <stop offset="1" stopColor="#D2BF98" />
      </linearGradient>
      <linearGradient id="paint5_linear_56_6211" x1="147.249" y1="110.563" x2="78.6329" y2="-68.2567" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint6_linear_56_6211" x1="59.6417" y1="144.183" x2="-8.97608" y2="-34.6379" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint7_linear_56_6211" x1="59.6417" y1="144.183" x2="-8.97608" y2="-34.6379" gradientUnits="userSpaceOnUse">
        <stop stopColor="#AD7F27" />
        <stop offset="1" stopColor="#D2BF98" />
      </linearGradient>
      <linearGradient id="paint8_linear_56_6211" x1="85.8623" y1="134.122" x2="17.2446" y2="-44.6991" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint9_linear_56_6211" x1="112.082" y1="124.06" x2="43.4642" y2="-54.7604" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint10_linear_56_6211" x1="138.302" y1="114" x2="69.6827" y2="-64.8225" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint11_linear_56_6211" x1="50.694" y1="147.616" x2="-17.9269" y2="-31.208" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint12_linear_56_6211" x1="50.694" y1="147.619" x2="-17.9311" y2="-31.2099" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D2BF98" />
        <stop offset="1" stopColor="#D2BF98" />
      </linearGradient>
      <linearGradient id="paint13_linear_56_6211" x1="76.9147" y1="137.555" x2="8.29389" y2="-41.2694" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint14_linear_56_6211" x1="103.134" y1="127.493" x2="34.5134" y2="-51.331" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint15_linear_56_6211" x1="103.134" y1="127.494" x2="34.5125" y2="-51.3314" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D2BF98" />
        <stop offset="1" stopColor="#AD7F27" />
      </linearGradient>
      <linearGradient id="paint16_linear_56_6211" x1="129.354" y1="117.432" x2="60.731" y2="-61.3941" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint17_linear_56_6211" x1="129.354" y1="117.432" x2="60.7315" y2="-61.3935" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D2BF98" />
        <stop offset="1" stopColor="#D2BF98" />
      </linearGradient>
      <linearGradient id="paint18_linear_56_6211" x1="41.7463" y1="151.051" x2="-26.8703" y2="-27.7687" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint19_linear_56_6211" x1="41.7463" y1="151.053" x2="-26.8747" y2="-27.7717" gradientUnits="userSpaceOnUse">
        <stop stopColor="#AD7F27" />
        <stop offset="1" stopColor="#D2BF98" />
      </linearGradient>
      <linearGradient id="paint20_linear_56_6211" x1="67.9669" y1="140.989" x2="-0.649545" y2="-37.8298" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint21_linear_56_6211" x1="67.9666" y1="140.991" x2="-0.655324" y2="-37.8323" gradientUnits="userSpaceOnUse">
        <stop stopColor="#AD7F27" />
        <stop offset="1" stopColor="#D2BF98" />
      </linearGradient>
      <linearGradient id="paint22_linear_56_6211" x1="94.1866" y1="130.928" x2="25.57" y2="-47.891" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint23_linear_56_6211" x1="94.1866" y1="130.93" x2="25.5656" y2="-47.8948" gradientUnits="userSpaceOnUse">
        <stop stopColor="#AD7F27" />
        <stop offset="1" stopColor="#D2BF98" />
      </linearGradient>
      <linearGradient id="paint24_linear_56_6211" x1="120.406" y1="120.867" x2="51.789" y2="-57.9531" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint25_linear_56_6211" x1="120.406" y1="120.869" x2="51.7858" y2="-57.9549" gradientUnits="userSpaceOnUse">
        <stop stopColor="#AD7F27" />
        <stop offset="1" stopColor="#D2BF98" />
      </linearGradient>
      <linearGradient id="paint26_linear_56_6211" x1="32.7986" y1="154.484" x2="-35.8226" y2="-24.3408" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint27_linear_56_6211" x1="59.0192" y1="144.422" x2="-9.60189" y2="-34.4022" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint28_linear_56_6211" x1="85.2389" y1="134.361" x2="16.6177" y2="-44.4639" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint29_linear_56_6211" x1="85.2389" y1="134.361" x2="16.6154" y2="-44.4658" gradientUnits="userSpaceOnUse">
        <stop stopColor="#AD7F27" />
        <stop offset="1" stopColor="#D2BF98" />
      </linearGradient>
      <linearGradient id="paint30_linear_56_6211" x1="111.459" y1="124.299" x2="42.8374" y2="-54.5256" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>

);
export default PatternIcon;
