import React from 'react';

const TiktokIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4059 6.96867C19.3675 6.74033 18.4282 6.18891 17.7227 5.39356C17.0171 4.5982 16.5817 3.59978 16.4788 2.54159V2.08325H12.8851V16.3228C12.8858 16.9547 12.6877 17.5707 12.3189 18.0837C11.95 18.5967 11.4291 18.9807 10.8299 19.1811C10.2308 19.3816 9.58368 19.3885 8.98037 19.2008C8.37706 19.0131 7.8481 18.6404 7.46841 18.1353C7.12826 17.6883 6.91996 17.155 6.86709 16.5957C6.81422 16.0365 6.91887 15.4736 7.16922 14.9707C7.41957 14.4679 7.80563 14.0451 8.28374 13.7502C8.76184 13.4553 9.31293 13.3 9.87466 13.302C10.1853 13.3002 10.4944 13.3459 10.7913 13.4374V9.79158C10.4458 9.7488 10.0977 9.73139 9.74966 9.7395C8.46769 9.77376 7.22354 10.1811 6.16954 10.9117C5.11555 11.6422 4.29746 12.6643 3.81547 13.8527C3.33348 15.0411 3.20849 16.3442 3.45583 17.6026C3.70316 18.8609 4.31207 20.0199 5.208 20.9374C6.12621 21.871 7.30122 22.5105 8.58372 22.7747C9.86622 23.0389 11.1983 22.9159 12.4108 22.4214C13.6232 21.9268 14.6613 21.0829 15.393 19.9971C16.1248 18.9112 16.5172 17.6323 16.5205 16.3228V9.03117C17.9699 10.0665 19.708 10.6204 21.4892 10.6145V7.07284C21.1394 7.07428 20.7903 7.03937 20.4476 6.96867H20.4059Z"
      fill="#AD7F27"
    />
  </svg>

);
export default TiktokIcon;
