import React from 'react';

const FacebookIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5357 0.857142C13.3326 0.857142 14.0123 1.14174 14.5748 1.71094C15.144 2.27344 15.4286 2.95312 15.4286 3.75V13.3929C15.4286 14.1897 15.144 14.8694 14.5748 15.4319C14.0123 16.0011 13.3326 16.2857 12.5357 16.2857H10.6473V10.3092H12.6462L12.9475 7.97879H10.6473V6.49219C10.6473 6.11719 10.7243 5.83594 10.8783 5.64844C11.0391 5.46094 11.3471 5.36719 11.8025 5.36719L13.0279 5.35714V3.2779C12.606 3.21763 12.01 3.1875 11.24 3.1875C10.3292 3.1875 9.59933 3.45536 9.05022 3.99107C8.50781 4.52678 8.23661 5.28348 8.23661 6.26116V7.97879H6.22768V10.3092H8.23661V16.2857H2.89286C2.09598 16.2857 1.41295 16.0011 0.84375 15.4319C0.28125 14.8694 0 14.1897 0 13.3929V3.75C0 2.95312 0.28125 2.27344 0.84375 1.71094C1.41295 1.14174 2.09598 0.857142 2.89286 0.857142H12.5357Z"
      fill="#AD7F27"
    />
  </svg>

);
export default FacebookIcon;
