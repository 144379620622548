import React, { useState } from 'react';

import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Pattern from '../icons/Pattern';
import Tiktok from '../icons/TiktokIcon';
import Twitter from '../icons/TwitterIcon';
import Facebook from '../icons/FacebookIcon';
import Instagram from '../icons/InstagramIcon';

const Footer = () => {
  const data = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "waychit.png"}) {
      childImageSharp {
        gatsbyImageData(width:200,placeholder: BLURRED)
      }
    }
  }
`);
  const footerListData = [
    {
      index: 1,
      title: 'Company',
      list: [
        {
          index: 1,
          name: 'About',
          href: '/#about',
        },
        {
          index: 2,
          name: 'Feature',
          href: '/#feature',
        },
        {
          index: 3,
          name: 'Process',
          href: '/#process',
        },
        {
          index: 4,
          name: 'Coupon',
          href: '/#coupon',
        },
        {
          index: 5,
          name: 'Privacy',
          href: '/privacy',
        },
      ],
    },
    {
      index: 2,
      title: 'Our Services',
      list: [
        {
          index: 1,
          name: 'Fuel Coupons',
        },
        {
          index: 2,
          name: 'Cash Power',
        },
        {
          index: 3,
          name: 'Airtime',
        },
        {
          index: 4,
          name: 'Food',
        },
      ],
    },
    {
      index: 3,
      itemScope: true,
      itemType: 'https://schema.org/ContactPoint',
      title: 'Reach Us',
      list: [
        {
          index: 1,
          name: '+2202368972',
          href: 'tel:+2202368972‬',
          itemProp: 'telephone',
        },
        {
          index: 2,
          name: 'info@waychit.com',
          href: 'mailto:info@assutech.gm',
          itemProp: 'email',
        },
      ],
    },
  ];
  const Item = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div className="border-t-0.6 border-solid border-muted items-center">
        <button
          type="button"
          aria-label="Open item"
          title="Open item"
          className="cursor-auto flex items-center justify-between w-full px-4 py-5 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className="text-lg font-medium text-heading text-left">{title}</p>
          <svg
            viewBox="0 0 24 24"
            className={`ml-md w-3 text-gray-600 transform transition-transform duration-200 ${
              isOpen ? 'rotate-180' : ''
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className={`p-4 pt-0 ${!isOpen ? 'hidden' : ''}`}>
          <p className="text-text font-normal text-16md md:text-2md-1">{children}</p>
        </div>
      </div>
    );
  };
  return (
    <footer className="snap-start bg-white pt-8 pb-sm lg:pb-8 relative z-10 px-5">
      <div
        className="container pt-12 justify-center mx-auto md:max-w-md lg:max-w-lg 2xl:max-w-2xl 3xl:max-w-3xl max-w-1/4"
      >
        <div className="flex flex-wrap -mx-4 py-4 md:py-8">
          <div className="w-full sm:w-1/2 lg:w-2/5 px-6">
            <div className="w-full mb-10">
              <Link to="/" className="cursor-auto inline-block" aria-label="Waychit logo">
                <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="waychit logo" />
              </Link>
              <p className="text-16md md:text-2md-1 font-normal text-text mt-20px mb-4 md:w-3/4" itemProp="description">
                Waychit is a tailored payment solution for SMEs provided by Assutech and aims
                to provide frictionless payment experiences. We help you Pay, Get Paid, and Shop
              </p>
              <span className="absolute top-[36%] left-[-120px] z-[-1]">
                <Pattern className="w-[85px] h-[107px]" />
              </span>
              <p className="flex items-center text-sm text-dark font-medium">
                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="facebook"
                  href="https://twitter.com/WaychitPay"
                  className="cursor-pointer file:text-primary mr-9 transform transition duration-500 hover:scale-200"
                >
                  <Twitter />
                </a>
                <a target="_blank" rel="noreferrer" aria-label="twitter" href="https://www.facebook.com/waychitPay" className="cursor-pointer text-primary mr-9 transform transition duration-500 hover:scale-200">
                  <Facebook />
                </a>
                <a target="_blank" rel="noreferrer" aria-label="twitter" href="https://www.instagram.com/waychitpay/" className="cursor-pointer text-primary mr-9 transform transition duration-500 hover:scale-200">
                  <Instagram />
                </a>
                <a target="_blank" rel="noreferrer" aria-label="twitter" href="https://www.tiktok.com/@waychitpay" className="cursor-pointer text-primary mr-9 transform transition duration-500 hover:scale-200">
                  <Tiktok />
                </a>
              </p>
            </div>
          </div>
          {footerListData.map((item) => (
            <div className="w-full sm:w-1/2 lg:w-1/5 px-4 hidden lg:flex" key={item.index}>
              <div className="w-full mb-10" itemScope={item.itemScope ? item.itemScope : ''} itemType={item.itemType ? item.itemType : ''}>
                <h6 className="text-heading text-16md md:text-2md font-medium mb-20px">{item.title}</h6>
                <ul>
                  {item.list.map((sub) => {
                    if (sub.href) {
                      return (
                        <li
                          key={sub.index}
                          itemProp={sub.itemProp ? sub.itemProp : ''}
                        >
                          <Link
                            alt={sub.name}
                            to={`${sub.href}`}
                            className="cursor-pointer text-16md md:text-2md-1 font-normal inline-block text-text
                            hover:text-hover transition duration-150 ease-in-out mb-2"
                          >
                            {sub.name}
                          </Link>
                        </li>
                      );
                    }
                    return (
                      <div key={sub.index}>
                        <div
                          className="font-Paragraph relative group cursor-pointer text-16md md:text-2md-1 font-normal inline-block text-text
                             hover:text-hover transition duration-150 ease-in-out mb-2"
                        >
                          {sub.name}
                          <span className="font-Paragraph absolute hidden group-hover:flex -top-2 -right-3 translate-x-full w-28 p-2 bg-background rounded-lg text-center text-white text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-background text-[14px]">
                            Coming Soon !
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              </div>
            </div>
          ))}
          {footerListData.map((item) => (
            <div className="lg:hidden w-full" key={item.index}>
              <Item title={item.title}>
                <ul>
                  {item.list.map((sub) => {
                    if (sub.href) {
                      return (
                        <li
                          key={sub.index}
                        >
                          <Link
                            to={`${sub.href}`}
                            alt={sub.name}
                            className="cursor-pointer text-16md md:text-2md-1 font-normal inline-block text-text
                        hover:text-hover transition duration-150 ease-in-out mb-2 min-h-[50px]"
                          >
                            {sub.name}
                          </Link>
                        </li>
                      );
                    }
                    return (
                      <div key={sub.index}>
                        <div
                          className="font-Paragraph relative group cursor-pointer text-16md md:text-2md-1 font-normal inline-block text-text
                               hover:text-hover transition duration-150 ease-in-out mb-2"
                        >
                          {sub.name}
                          <span className="font-Paragraph absolute hidden group-hover:flex -top-2 -right-3 translate-x-full w-28 p-2 bg-background rounded-lg text-center text-white text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-background text-[14px]">
                            Coming Soon !
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              </Item>
            </div>
          ))}
        </div>
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
          <p className="text-7md md:text-2md-1 font-normal text-text">
            All Rights Reserved.
          </p>
          <p className="text-7md md:text-2md-1 font-normal text-text mr-4">
            ©
            {new Date().getFullYear()}
            |
            Assutech Inc.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
