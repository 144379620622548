import React from 'react';

const TwitterIcon = () => (
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2723 1.66964C15.8237 2.32589 15.2813 2.88504 14.6451 3.3471C14.6518 3.44085 14.6551 3.58147 14.6551 3.76897C14.6551 4.63951 14.5279 5.51004 14.2734 6.38058C14.019 7.24442 13.6306 8.07478 13.1083 8.87165C12.5926 9.66853 11.9766 10.375 11.26 10.9911C10.5435 11.6004 9.67969 12.0893 8.66853 12.4576C7.65737 12.8192 6.57589 13 5.42411 13C3.60938 13 1.94866 12.5145 0.441964 11.5435C0.676339 11.5703 0.9375 11.5837 1.22545 11.5837C2.73214 11.5837 4.07478 11.1217 5.25335 10.1975C4.55022 10.1842 3.92076 9.96987 3.36496 9.55469C2.80915 9.13281 2.42746 8.5971 2.21987 7.94754C2.44085 7.98103 2.64509 7.99777 2.83259 7.99777C3.12054 7.99777 3.40513 7.96094 3.68638 7.88728C2.93638 7.73326 2.31362 7.36161 1.81808 6.77232C1.32924 6.17634 1.08482 5.48661 1.08482 4.70312V4.66295C1.54018 4.91741 2.02902 5.05469 2.55134 5.07478C2.10938 4.78013 1.75781 4.39509 1.49665 3.91964C1.23549 3.4442 1.10491 2.92857 1.10491 2.37277C1.10491 1.78348 1.25223 1.23772 1.54688 0.735491C2.35714 1.73326 3.34152 2.53348 4.5 3.13616C5.66518 3.73214 6.91071 4.06362 8.23661 4.13058C8.18304 3.87612 8.15625 3.62835 8.15625 3.38728C8.15625 2.48995 8.47098 1.72656 9.10045 1.0971C9.73661 0.460937 10.5033 0.142857 11.4007 0.142857C12.3382 0.142857 13.1283 0.484375 13.7712 1.16741C14.5011 1.02679 15.1875 0.765625 15.8304 0.383928C15.5826 1.15402 15.1071 1.75 14.404 2.17188C15.0268 2.10491 15.6496 1.9375 16.2723 1.66964Z"
      fill="#AD7F27"
    />
  </svg>

);
export default TwitterIcon;
